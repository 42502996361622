import * as React from "react"
import "../scss/NewsItemPage.scss"
import Seo from "../components/seo/seo"

import SocialMedias from "../shared/social-medias/SocialMedias"
import NewsListPage from "../shared/news-list/NewsListPage"
import { getImageUrl } from "../services/Utils"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"

const NewsItemPageDynamic = props => {
  const newsItem = props.pageContext.newsItem
  const news = props.pageContext.news
  const menu = props.pageContext.menu
  const staticNewsPage = props.pageContext.staticNewsPage
  const staticHeaderPage = props.pageContext.staticHeaderPage

  const determineBottomList = (newsItem, news) => {
    const currentIndex = news.findIndex(i => i.id === newsItem.id)
    const prevIndex = currentIndex !== 0 ? currentIndex - 1 : null
    const nextIndex = currentIndex !== news.length - 1 ? currentIndex + 1 : null

    let bottomItemsRow = []
    const prevNewsItem = prevIndex !== null ? news[prevIndex] : null
    const nextNewsItem = nextIndex ? news[nextIndex] : null

    if (prevNewsItem !== null) {
      bottomItemsRow.push(prevNewsItem)
    }
    if (nextNewsItem) {
      bottomItemsRow.push(nextNewsItem)
    }
    return bottomItemsRow
  }

  return (
    <div>
      <div className="container">
        <Header
          menu={menu}
          siteTitle={staticNewsPage["<title>"]}
          contents={staticHeaderPage}
          displayLogoLinks={false}
        />
        <Seo
          keywordsContent={staticNewsPage["<meta> keywords"]}
          descriptionContent={staticNewsPage["<meta> description"]}
          title={staticNewsPage["<title>"]}
          otherLangsUrls={newsItem?.newsLangUrls}
        />
        <div className="news-item row mt-4">
          <div className="row mb-4">
            {/* <div className="col-lg-1"></div> */}
            <h2 className="col text-center">{newsItem.title}</h2>
          </div>

          <div className="mb-4 d-flex justify-content-center">
            <img
              src={getImageUrl(newsItem?.imageUrl)}
              alt="News item"
              className="rounded-img image-title"
            />
          </div>

          <div className="row article-text">
            <div className="col-lg-3"></div>
            <div
              className="col"
              dangerouslySetInnerHTML={{ __html: newsItem.details }}
            />
            <div className="col-lg-3"></div>
          </div>
          <SocialMedias staticNewsPage={staticNewsPage} />
          <div className="row mt-5">
            <h1 className="col-lg-12">{staticNewsPage["Read more text"]}</h1>
          </div>
          <NewsListPage
            items={determineBottomList(newsItem, news)}
            itemsInRow={2}
            learnMoreBtnText={staticNewsPage["Details Url Title"]}
          />
          <div className="mb-6"></div>
        </div>
      </div>
      <Footer recaptchaSettings={props.pageContext.recaptchaSettings} staticContactsForm={props.pageContext.staticContactsForm} staticContactsPage={props.pageContext.staticContactsPage} />
    </div>
  )
}

export default NewsItemPageDynamic
